<template>
  <div>
    <ResetPasswordForm></ResetPasswordForm>
  </div>
</template>

<script>
import ResetPasswordForm from '@/components/resetPasswordForm'

export default {
  name: 'resetPassword',
  components: {
    ResetPasswordForm
  }
}
</script>
